<template>
  <div id="permissions">
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title class="title">
          {{ $t('permissions.conf_permissions') }}
        </v-toolbar-title>
        <v-spacer />
      </v-toolbar>
      <v-card-text>
        <v-card outlined class="grey lighten-5 px-5">
          <v-row>
            <template v-for="(value, index) in generalPermissions">
              <v-col
                v-if="
                  configByAdminPermissions.length === 0 ||
                    configByAdminPermissions.indexOf(index) > -1 ||
                    showAdditionalSettings
                "
                :key="index"
                cols="12"
                xs="12"
                sm="12"
                md="3"
              >
                <v-switch v-model="permissions[index]" inset :label="titleTranslated(index)" color="success" />
              </v-col>
            </template>
          </v-row>
        </v-card>
      </v-card-text>
    </v-card>

    <v-card v-if="prescriptionsPermissions" class="mt-5">
      <v-toolbar flat>
        <v-toolbar-title class="title">
          {{ $t('permissions.prescriptionsPermissions') }}
        </v-toolbar-title>
        <v-spacer />
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col v-for="(value, parentIndex) in prescriptionsPermissions" xs="12" sm="12" md="6">
            <v-card flat>
              <v-card-title class="grey lighten-4">
                {{ titleTranslated(parentIndex) }}
              </v-card-title>
              <v-card-text>
                <v-row>
                  <template v-for="(value, index) in prescriptionsPermissions[parentIndex]">
                    <v-col
                      v-if="
                        configByAdminPermissions.length === 0 ||
                          configByAdminPermissions.indexOf(index) > -1 ||
                          showAdditionalSettings
                      "
                      :key="index"
                      cols="12"
                      xs="12"
                      sm="12"
                      :md="12 / Object.keys(prescriptionsPermissions[parentIndex]).length"
                    >
                      <v-switch v-model="permissions[index]" inset :label="titleTranslated(index)" color="success" />
                    </v-col>
                  </template>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card v-if="personalDoctorPermissions" class="mt-5">
      <v-toolbar flat>
        <v-toolbar-title class="title">
          {{ $t('permissions.personalDoctor') }}
        </v-toolbar-title>
        <v-spacer />
      </v-toolbar>
      <v-card-text>
        <v-card outlined class="grey lighten-5 px-5">
          <v-row>
            <template v-for="(value, index) in personalDoctorPermissions">
              <v-col
                v-if="
                  configByAdminPermissions.length === 0 ||
                    configByAdminPermissions.indexOf(index) > -1 ||
                    showAdditionalSettings
                "
                :key="index"
                cols="12"
                xs="12"
                sm="12"
                md="3"
              >
                <v-switch
                  v-model="permissions['personalDoctor'][index]"
                  inset
                  :label="titleTranslated(index)"
                  color="success"
                />
              </v-col>
            </template>
          </v-row>
        </v-card>
      </v-card-text>
    </v-card>

    <div class="text-center pt-5 mt-5 mb-5">
      <v-btn
        :loading="isSaving"
        depressed
        :disabled="disabledButton || isSaving"
        color="primary"
        @click="savePermissions()"
        >{{ $t('permissions.save_permissions') }}</v-btn
      >
    </div>
  </div>
</template>

<script>
import Alerts from '@/mixins/Alerts';
import { getHeader, newCompaniesPermission } from '../../../config/config';
import environment from "@/environment";

export default {
  name: 'Permissions',
  mixins: [Alerts],
  props: {
    permissions: Object,
    defaultPermissions: Object,
    showAdditionalSettings: {
      type: Boolean,
      default: false,
    },
    configByAdminPermissions: Array,
  },
  data() {
    return {
      isSaving: false,
      authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
      disabledButton: true,
      mode: '',
    };
  },

  computed: {
    globalPermissions() {
      return Object.fromEntries(Object.entries(this.permissions).filter(([_, value]) => typeof value === 'boolean'));
    },
    generalPermissions() {
      const excludedPermissions = [
          ...environment.permissions.notConfigurable,
        'settingsSign',
        'myPrescriptions',
        'settingsBook',
        'prescriptions',
        'haveElectronicPrescription',
        'prescriptionsList',
        'parapharmacyPrescription',
      ];
      return Object.fromEntries(
        Object.entries(this.globalPermissions).filter(([key]) => {
          return !excludedPermissions.includes(key);
        })
      );
    },
    prescriptionsPermissions() {
      const prescriptionPermissions = {
        generalPermissionsPrescriptions: {
          settingsSign: this.globalPermissions.settingsSign,
          myPrescriptions: this.globalPermissions.myPrescriptions,
        },
        pdfPrescriptions: {
          settingsBook: this.globalPermissions.settingsBook,
          prescriptions: this.globalPermissions.prescriptions,
        },
        electronicPrescriptions: {
          haveElectronicPrescription: this.globalPermissions.haveElectronicPrescription,
          prescriptionsList: this.globalPermissions.prescriptionsList,
        },
      };

      if (typeof this.globalPermissions.parapharmacyPrescription !== 'undefined') {
        prescriptionPermissions.parapharmacyPrescriptionPermissions = {
          parapharmacyPrescription: this.globalPermissions.parapharmacyPrescription,
        };
      }

      return prescriptionPermissions;
    },
    personalDoctorPermissions() {
      return this.permissions['personalDoctor'];
    }
  },

  watch: {
    permissions: {
      handler() {
        this.enableSubmit();
      },
      deep: true,
    },
  },
  methods: {
    titleTranslated(index) {
      return this.$t('permissions.' + index + '');
    },

    savePermissions() {
      this.isSaving = true;
      this.$http
        .post(newCompaniesPermission(this.$route.params.id), this.permissions, { headers: getHeader() })
        .then(_ => {
          this.toastSuccess(this.$t('empresa.edit_empresa_success'));
          this.disabledButton = true;
        })
        .catch(err => {
          this.$log.error(err);
        })
        .finally(() => {
          this.isSaving = false;
        });
    },

    enableSubmit() {
      this.disabledButton = false;
    },

    showPermission(index) {
      const permissionValue = this.defaultPermissions[index];
      if (typeof permissionValue !== 'undefined' && permissionValue === true) {
        return true;
      }

      return false;
    },
  },
};
</script>
